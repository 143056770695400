import { ref, computed, watch } from '@vue/composition-api'
// import { useToast } from 'vue-toastification/composition'
import { debounce } from 'lodash'

import api from '@/api'
import store from '@/store'
import { resolveEmployeeType } from '@/constants/selectOptions'

// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import useToast from '@useToast'
// import toastMessage from '@toast'

export default function useEmployeeHandle() {
  // Use toast
  const { toastSuccess, toastError } = useToast()

  // const lang = localStorage.getItem('Locale') // Lấy ngôn ngữ từ local storage
  // const { successful } = toastMessage[lang].messagesList

  // Refs
  const refEmployeeListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { label: 'checkbox', key: 'checkbox' },
    { label: 'empCode', key: 'employeeCode' },
    { label: 'empInfo', key: 'information' },
    { label: 'tabInfo', key: 'account' },
    { label: 'created', key: 'createdAt', sortable: true },
    { label: 'updated', key: 'updatedAt', sortable: true },
    { label: 'status', key: 'isActive' },
    { label: 'actions', key: 'actions' },
  ]

  const tableCustomers = [
    { label: 'Full Name', key: 'information' },
    { label: 'Date Of Birth', key: 'birthDay' },
    { label: 'Phone Number', key: 'phoneNumber' },
    { label: 'Description', key: 'description' },
  ]

  const sizePerPage = ref(40)
  const totalEmployees = ref(0)
  const currentPage = ref(1)

  // Loading
  const loading = ref(null)

  // Sort
  const sortBy = ref(null) // createdAt || updatedAt
  const isSortDirDesc = ref(true) //  ASC || DESC

  // Filter
  const searchTextFilter = ref(null)
  const typeFilter = ref([])
  const statusFilter = ref(true)
  const genderFilter = ref([])
  const createdByFilter = ref(null)
  const employeeCode = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refEmployeeListTable.value
      ? refEmployeeListTable.value.localItems.length
      : 0
    return {
      from:
        sizePerPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: sizePerPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalEmployees.value,
    }
  })

  // Empty filter
  const isEmptyFilter = computed(
    () => !!(
      typeFilter.value.length === 0
        && createdByFilter.value === null
        && statusFilter.value === null
        && genderFilter.value.length === 0
        && employeeCode.value.length === 0
    ),
  )

  const refetchData = () => {
    refEmployeeListTable.value.refresh()
  }

  const clearFilter = () => {
    console.log('own clearFilter')
    searchTextFilter.value = null
    typeFilter.value.splice(0, typeFilter.value.length)
    createdByFilter.value = null
    statusFilter.value = null
    genderFilter.value.splice(0, genderFilter.value.length)
    // statusFilter.value.splice(0, statusFilter.value.length)
    employeeCode.value = ''

    refetchData()
  }

  watch(
    [currentPage, sizePerPage, sortBy, isSortDirDesc, searchTextFilter],
    () => {
      refetchData()
    },
  )

  const fetchEmployeeById = id => {
    store
      .dispatch('app-customer/fetchEmployeeById', { id })
      .then(() => {})
      .catch(() => {
        toastError({
          title: 'messagesList.error',
          content: 'messagesList.employee.errorFetching',
        })
      })
  }

  const fetchAllEmployees = (ctx, callback) => {
    store
      .dispatch('app-employee/fetchAllEmployees')
      .then(response => {
        totalEmployees.value = response.data.total
        const emp = response.data
        callback(emp.items)
      })
      .catch(error => {
        console.error({ error })
        toastError({
          title: 'messagesList.error',
          content: 'messagesList.employee.errorFetching',
        })
      })
  }

  const fetchEmployeesByFilter = (ctx, callback) => {
    loading.value = true
    const payload = {
      searchText: searchTextFilter.value || '',
      size: sizePerPage.value,
      page: currentPage.value,
      types: typeFilter.value.length !== 0 ? typeFilter.value : null,
      genders: genderFilter.value,
      isActive: statusFilter.value,
      createdById: createdByFilter.value
        ? createdByFilter.value.id
        : createdByFilter.value,
      sortBy: sortBy.value ? sortBy.value : 'createdAt',
      sortDirection: isSortDirDesc.value ? 'DESC' : 'ASC',
      employeeCode: employeeCode.value || null,
    }
    store
      .dispatch('app-employee/fetchEmployeesByFilter', payload)
      .then(response => {
        totalEmployees.value = response.total
        callback(response.items)
        loading.value = false
      })
      .catch(error => {
        console.error({ error })
        toastError({
          title: 'messagesList.error',
          content: 'messagesList.employee.errorFetching',
        })
        loading.value = false
      })
  }

  const addEmployee = employee => new Promise((resolve, reject) => {
    store
      .dispatch('app-employee/addEmployee', employee)
      .then(res => {
        toastSuccess({
          title: 'messagesList.success',
          content: 'messagesList.employee.createSuccess',
        })
        resolve(res)
      })
      .catch(error => {
        toastError({
          title: 'messagesList.error',
          content: 'messagesList.employee.createError',
        })
        reject(error)
      })
  })

  const updateEmployee = (employeeData, dataToUpdate) => new Promise((resolve, reject) => {
    store
      .dispatch('app-employee/updateEmployee', {
        id: employeeData.id,
        dataToUpdate,
      })
      .then(res => {
        resolve(res)
        toastSuccess({
          title: 'messagesList.success',
          content: 'messagesList.employee.updateSuccess',
        })
      })
      .catch(error => {
        reject(error)
        toastError({
          title: 'messagesList.error',
          content: 'messagesList.employee.updateError',
        })
      })
  })

  const updateActive = employee => store
    .dispatch('app-employee/updateActive', employee)
    .then(() => {
      refetchData()
      toastSuccess({
        title: 'messagesList.success',
        content: 'messagesList.employee.updatedStatusSuccess',
      })
    })
    .catch(() => {
      toastError({
        title: 'messagesList.error',
        content: 'messagesList.employee.updatedStatusError',
      })
    })

  const updateDeactive = employee => store
    .dispatch('app-employee/updateDeactive', employee)
    .then(() => {
      refetchData()
      toastSuccess({
        title: 'messagesList.success',
        content: 'messagesList.employee.updatedStatusSuccess',
      })
    })
    .catch(() => {
      toastError({
        title: 'messagesList.error',
        content: 'messagesList.employee.updatedStatusError',
      })
    })

  const deleteEmployee = employee => {
    store
      .dispatch('app-employee/deleteEmployee', { id: employee.id })
      .then(() => {
        toastSuccess({
          title: 'messagesList.success',
          content: 'messagesList.employee.deleteSuccess',
        })
      })
      .catch(() => {
        toastError({
          title: 'messagesList.error',
          content: 'messagesList.employee.deleteError',
        })
      })
  }

  const bulkDeleteEmployees = ids => {
    store
      .dispatch('app-employee/bulkDeleteEmployees', { ids })
      .then(() => {
        refetchData()
        toastSuccess({
          title: 'messagesList.success',
          content: 'messagesList.employee.deleteSuccess',
        })
      })
      .catch(() => {
        toastError({
          title: 'messagesList.error',
          content: 'messagesList.employee.deleteError',
        })
      })
  }

  const exportEmployees = locale => {
    const timeZoneOffset = new Date().getTimezoneOffset()
    store
      .dispatch('app-employee/exportEmployees', { language: locale, timeZoneOffset })
      .then(() => {
        toastSuccess({
          title: 'messagesList.success',
          content: 'messagesList.employee.exportSuccess',
        })
      })
      .catch(() => {
        toastError({
          title: 'messagesList.error',
          content: 'messagesList.employee.exportError',
        })
      })
  }

  // Sample file csv
  const exportEmployeesTemplateCsv = () => {
    store
      .dispatch('app-employee/exportEmployeesTemplate')
      .then(() => {
        toastSuccess({
          title: 'messagesList.success',
          content: 'messagesList.employee.exportSampleSuccess',
        })
      })
      .catch(() => {
        toastError({
          title: 'messagesList.error',
          content: 'messagesList.employee.exportSampleError',
        })
      })
  }

  const importEmployees = arrayList => new Promise((resolve, reject) => {
    store
      .dispatch('app-employee/importEmployees', { employees: arrayList })
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })

  // function fetchAgencies(payload) {
  //   return new Promise((resolve, reject) => {
  //     api.agencies.fetchAgencies(payload).then(res => resolve(res)).catch(e => reject(e))
  //   })
  // }

  // fetch Agencies
  const agenciesManagerList = ref([])
  const agenciesManagerListDefault = ref([])

  const fetchAgenciesManagerByFilter = async (search = '') => {
    agenciesManagerList.value = []
    loading.value = true
    try {
      const { data } = await api.agencies.fetchAgencies({
        isActive: true,
        searchText: search,
      })
      if (data && !search) {
        agenciesManagerListDefault.value = data.items
      }
      agenciesManagerList.value = data.items
    } catch (error) {
      console.error(error)
    } finally {
      loading.value = false
    }
  }

  const openAgenciesManager = () => {
    if (agenciesManagerListDefault.value.length === 0) {
      fetchAgenciesManagerByFilter()
    } else {
      agenciesManagerList.value = agenciesManagerListDefault.value
    }
  }

  const searchAgenciesManager = debounce(search => {
    if (search) fetchAgenciesManagerByFilter(search)
  }, 300)

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveEmployeeTypeVariant = type => {
    if (type === 'ADM') return 'danger'
    if (type === 'OPE') return 'warning'
    if (type === 'KTT') return 'success'
    if (type === 'KTV') return 'success'
    if (type === 'BE') return 'info'
    if (type === 'SE') return 'primary'
    return 'primary'
  }

  const resolveEmployeeTypeIcon = type => {
    if (type === 'ADM') return 'KeyIcon'
    if (type === 'OPE') return 'ServerIcon'
    if (type === 'KTT') return 'ArchiveIcon'
    if (type === 'KTV') return 'BookIcon'
    if (type === 'BE') return 'UserIcon'
    if (type === 'SE') return 'UserIcon'
    return 'UserIcon'
  }

  const resolveEmployeeTypeTitle = type => resolveEmployeeType(type, 'value', 'label')

  const resolveEmployeeIsActiveVariant = isActive => {
    if (isActive === true) return 'success'
    if (isActive === false) return 'secondary'
    return 'primary'
  }

  return {
    fetchEmployeeById,
    fetchAllEmployees,
    fetchEmployeesByFilter,
    addEmployee,
    updateEmployee,
    deleteEmployee,
    updateActive,
    updateDeactive,
    bulkDeleteEmployees,
    tableColumns,
    sizePerPage,
    currentPage,
    totalEmployees,
    dataMeta,

    sortBy,
    isSortDirDesc,

    // Refs
    refEmployeeListTable,

    resolveEmployeeTypeVariant,
    resolveEmployeeTypeIcon,
    resolveEmployeeTypeTitle,
    resolveEmployeeIsActiveVariant,
    refetchData,
    clearFilter,

    // Extra Filters
    typeFilter,
    genderFilter,
    searchTextFilter,
    statusFilter,
    createdByFilter,
    employeeCode,

    // Customer
    tableCustomers,

    exportEmployees,
    exportEmployeesTemplateCsv,
    importEmployees,

    // Loading
    loading,

    // Empty filter
    isEmptyFilter,
    // fetchAgencies,
    fetchAgenciesManagerByFilter,
    openAgenciesManager,
    searchAgenciesManager,
    agenciesManagerList,
  }
}
