import api, { apiEmployee } from '@/api/'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchEmployeeById(ctx, { id }) {
      return api.employee.getEmployeeById(id)
    },

    fetchAllEmployees(ctx, params) {
      return api.employee.fetchAllEmployees(params)
    },

    fetchEmployeesByFilter(ctx, params) {
      return api.employee.fetchEmployeesByFilter(params)
    },

    addEmployee(ctx, employeeData) {
      return api.employee.addEmployee(employeeData)
    },

    updateEmployee(ctx, employeeDataToUpdate) {
      return api.employee.updateEmployee(
        employeeDataToUpdate.id,
        employeeDataToUpdate.dataToUpdate,
      )
    },

    deleteEmployee(ctx, { id }) {
      return api.employee.deleteEmployee(id)
    },

    updateActive(ctx, id) {
      return api.employee.updateActive(id)
    },
    updateDeactive(ctx, id) {
      return api.employee.updateDeactive(id)
    },

    bulkDeleteEmployees(cxt, ids) {
      return api.employee.bulkDeleteEmployees(ids)
    },

    exportEmployees(ctx, payload, fileName = 'Employees-List') {
      return api.employee.exportEmployees(payload, fileName)
    },

    exportEmployeesTemplate(ctx, fileName = 'Employee-Template') {
      return api.employee.exportEmployeesTemplate(fileName)
    },

    importEmployees(ctx, payload) {
      return api.employee.bulkInsert(payload)
    },
    assignAgencies(ctx, { id, payload }) {
      return apiEmployee.assignAgencies(id, payload)
    },
  },
}
