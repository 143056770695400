import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'

import store from '@/store'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useErrorNotiHandle() {
  const toast = useToast()

  // Loading
  const loading = ref(null)
  // Refs
  const refErrorNotiListTable = ref(null)

  const tableColumnsInvoice = [
    { label: 'no', key: 'no' },
    { label: 'type', key: 'type' },
    { label: 'invoices', key: 'invoices' },
    { label: 'taAcceptNo', key: 'taAcceptNo' },
    { label: 'taAcceptDate', key: 'taAcceptDate' },
    { label: 'status', key: 'status' },
    { label: 'created', key: 'createdAt', sortable: true },
    { label: 'updated', key: 'updatedAt', sortable: true },
    { label: 'actions', key: 'actions' },
  ]

  // Filter
  const sizePerPage = ref(10)
  const currentPage = ref(1)
  const totalItems = ref(0)
  const sortBy = ref(null) // createdAt || updatedAt
  const isSortDirDesc = ref(true) //  ASC || DESC
  const fixFlightType = ref('')
  const searchText = ref(null)
  // filtersParams includes: startDate, endDate, agencyCode, flightType, isInvoiced, bookingCode,
  let filtersParams = {}

  const dataMeta = computed(() => {
    const localItemsCount = refErrorNotiListTable.value
      ? refErrorNotiListTable.value.localItems.length
      : 0
    return {
      from:
        sizePerPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: sizePerPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const selectedIds = ref([])
  const selectionCount = computed(() => selectedIds.value.length)
  const notSelect = computed(() => selectionCount.value === 0)
  const isChecked = id => selectedIds.value.includes(id)
  const setArray = (array, id) => {
    const index = array ? array.findIndex(element => element === id) : -1
    // eslint-disable-next-line no-unused-expressions
    index >= 0 ? array.splice(index, 1) : array.push(id)

    return array
  }
  const chooseItem = id => {
    selectedIds.value = setArray(selectedIds.value, id)
  }

  const refetchData = ($event = {}) => {
    filtersParams = { ...$event }
    refErrorNotiListTable.value.refresh()
  }

  const clearFilter = () => {
    searchText.value = null
    refetchData()
  }

  watch([currentPage, sizePerPage, sortBy, isSortDirDesc], () => {
    refetchData()
  })

  // *===============================================---*
  // *--------- DISPATCH STORE ---------------------------------------*
  // *===============================================---*

  const fetchErrorNoti = (ctx, callback) => {
    loading.value = true

    store
      .dispatch('app-error-noti/fetchErrorNoti', {
        ...filtersParams,
        searchText: searchText.value || undefined,
        sortBy: sortBy.value ? sortBy.value : 'createdAt',
        sortDirection: isSortDirDesc.value ? 'DESC' : 'ASC',
        size: sizePerPage.value,
        page: currentPage.value,
      })
      .then(response => {
        totalItems.value = response.total
        callback(response.items)
      })
      .catch(error => {
        console.error('error', error)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching error noti list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .finally(() => {
        loading.value = false
      })
  }

  const createErrorNoti = errorNoti => new Promise((resolve, reject) => {
    store
      .dispatch('app-error-noti/createErrorNoti', errorNoti)
      .then(res => {
        toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Successful',
            icon: 'CoffeeIcon',
            variant: 'success',
            text: 'Created successful error notification',
          },
        })
        resolve(res)
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error created error notification',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.message,
          },
        })
        reject(error)
      })
  })

  const sendFptTvan = (param, id) => new Promise((resolve, reject) => {
    store
      .dispatch('app-error-noti/sendFptTvan', { param, id })
      .then(res => {
        toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Successful',
            icon: 'CoffeeIcon',
            variant: 'success',
            text: 'Send Fpt Tvan successful',
          },
        })
        resolve(res)
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error Send Fpt Tvan',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.message,
          },
        })
        reject(error)
      })
  })

  const exportSaleReport = () => {
    store
      .dispatch('app-sale-report/exportSaleReport', {
        ...filtersParams,
        bookingCode: '',
        page: currentPage.value,
        size: sizePerPage.value,
        sortBy: 'createdAt',
        sortDirection: 'DESC',
        searchText: searchText.value || '',
      })
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Export successfully',
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
      })
      .catch(error => {
        console.error({ error })
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error export agency',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteErrorNoti = id => {
    store
      .dispatch('app-error-noti/deleteErrorNoti', { id })
      .then(() => {
        refetchData()
        toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Success',
            icon: 'CoffeeIcon',
            variant: 'success',
            text: 'Deleted error noti success ',
          },
        })
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error delete employee',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const refuseToSign = (param, id) => new Promise((resolve, reject) => {
    store
      .dispatch('app-error-noti/refuseToSign', { param, id })
      .then(res => {
        toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Successful',
            icon: 'CoffeeIcon',
            variant: 'success',
            text: 'Refuse to sign successful',
          },
        })
        resolve(res)
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error Refuse to sign',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.message,
          },
        })
        reject(error)
      })
  })

  const exportErrorNoti = () => {
    store
      .dispatch('app-error-noti/exportErrorNoti', {
        ...filtersParams,
        searchText: searchText.value || undefined,
        sortBy: sortBy.value ? sortBy.value : 'createdAt',
        sortDirection: isSortDirDesc.value ? 'DESC' : 'ASC',
        size: sizePerPage.value,
        page: currentPage.value,
        language: 'vi',
      })
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Export successfully',
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
      })
      .catch(error => {
        console.error({ error })
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error export',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    // Loading
    loading,
    // Refs
    refErrorNotiListTable,
    tableColumnsInvoice,

    // Filters
    sizePerPage,
    currentPage,
    dataMeta,
    sortBy,
    isSortDirDesc,
    // Extra Filters
    fixFlightType,
    searchText,

    totalItems,
    selectedIds,
    selectionCount,
    notSelect,

    isChecked,
    chooseItem,
    refetchData,
    clearFilter,

    // DISPATCH STORE
    fetchErrorNoti,
    createErrorNoti,
    exportSaleReport,
    sendFptTvan,
    deleteErrorNoti,
    refuseToSign,
    exportErrorNoti,
  }
}
