import api from '@/api/'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCustomerById(ctx, { id }) {
      return api.customer.getCustomerById(id)
    },

    fetchCustomers(ctx, params) {
      return api.customer.fetchCustomers(params)
    },

    addCustomer(ctx, customerData) {
      return api.customer.addCustomer(customerData)
    },

    updateCustomer(ctx, customerDataToUpdate) {
      return api.customer.updateCustomer(
        customerDataToUpdate.id,
        customerDataToUpdate.dataToUpdate,
      )
    },

    deleteCustomer(ctx, id) {
      return api.customer.deleteCustomer(id)
    },

    bulkDeleteCustomers(cxt, ids) {
      return api.customer.bulkDeleteCustomers(ids)
    },

    fetchContacts(ctx, { customerId, payload }) {
      return api.customer.fetchContacts(customerId, payload)
    },

    getContactById(ctx, { id, contactId }) {
      return api.customer.getContactById(id, contactId)
    },

    addContact(ctx, { id, contact }) {
      return api.customer.addContact(id, contact)
    },

    updateContact(ctx, contactDataToUpdate) {
      return api.customer.updateContact(
        contactDataToUpdate.id,
        contactDataToUpdate.contactId,
        contactDataToUpdate.dataToUpdate,
      )
    },

    deleteContact(ctx, { customerId, contactId }) {
      return api.customer.deleteContact(customerId, contactId)
    },

    exportCustomers(ctx, payload, fileName = 'Customers-List') {
      return api.customer.exportCustomers(payload, fileName)
    },

    exportCustomersTemplate(ctx, fileName = 'Customers-Template') {
      return api.customer.exportCustomersTemplate(fileName)
    },

    importCustomers(ctx, payload) {
      return api.customer.bulkInsert(payload)
    },

    fetchCountries(ctx, payload) {
      return api.customer.getCountries(payload)
    },

    fetchAdministrativeUnits(ctx, params) {
      return api.customer.getAdministrativeUnits(params)
    },
    changeSocialNetwork(ctx, { id, payload }) {
      return api.customer.changeSocialNetwork(id, payload)
    },
  },
}
