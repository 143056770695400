import { apiDocument } from '@/api/'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAllFolders(ctx, params) {
      return apiDocument.fetchAllFolders(params)
    },
    fetchAllDocuments(ctx, params) {
      return apiDocument.fetchAllDocuments(params.id, params)
    },
    addDocument(ctx, payload) {
      return apiDocument.addDocument(payload)
    },
    getDocumentById(ctx, { id }) {
      return apiDocument.getDocumentById(id)
    },
    updateDocument(ctx, documentDataToUpdate) {
      return apiDocument.updateDocument(
        documentDataToUpdate.id,
        documentDataToUpdate.dataToUpdate,
      )
    },
    deleteDocument(cxt, id) {
      return apiDocument.deleteDocument(id)
    },
    pinDocument(cxt, { id, payload }) {
      return apiDocument.pinDocument(id, payload)
    },
    bulkDeleteDocuments(cxt, id) {
      return apiDocument.bulkDeleteDocuments(id)
    },

    activeDocument(id) {
      return apiDocument.activeDocument(id)
    },
    inactiveDocument(id) {
      return apiDocument.inactiveDocument(id)
    },
    showDocument(id) {
      return apiDocument.showDocument(id)
    },
    inShowDocument(id) {
      return apiDocument.inShowDocument(id)
    },
    getAllPins() {
      return apiDocument.getAllPins()
    },
  },
}
