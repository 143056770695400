export default function useErrorNotiAddHandle() {
  const tableColumnsAdd = [
    { label: 'orderNo', key: 'orderNo' },
    { label: 'invoiceNumber', key: 'invoiceNumber' },
    { label: 'serial', key: 'serial' },
    { label: 'issueDate', key: 'publicDate' },
    { label: 'taCode', key: 'taCode' },
    { label: 'invoiceType', key: 'invoiceType' },
    { label: 'reason', key: 'cancelReason' },
    { label: 'action', key: 'action' },
  ]

  const ErrorNotificationType = {
    NEW: '0',
    ROOT: '0',
    CANCEL: '1',
    ADJUST: '2',
    REPLACE: '3',
    EXPLAIN: '4',
    ERROR: '5',
  }

  return {
    tableColumnsAdd,
    ErrorNotificationType,
  }
}
