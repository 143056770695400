import { ref, computed, watch } from '@vue/composition-api'

import store from '@/store'
import { useRouter } from '@/@core/utils/utils'

import useToast from '@useToast'

export default function useCustomerHandle() {
  const { toastError, toastSuccess } = useToast()

  const { route } = useRouter()

  // Refs
  const refCustomerListTable = ref(null)
  const refContactListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { label: 'checkbox', key: 'checkbox' },
    { label: 'code', key: 'code' },
    { label: 'tabInfo', key: 'information' },
    { label: 'created', key: 'createdAt', sortable: true },
    { label: 'updated', key: 'updatedAt', sortable: true },
  ]

  // Table Handlers
  const tableContacts = [
    { label: 'fullName', key: 'information' },
    { label: 'dateOfBirth', key: 'birthDay' },
    { label: 'typeOfPassenger', key: 'type' },
    { label: 'Action', key: 'action' },
  ]

  const sizePerPage = ref(40)
  const totalCustomers = ref(0)
  const currentPage = ref(1)
  const sizePerPageContacts = ref(40)
  const totalContacts = ref(0)
  const currentPageContacts = ref(1)

  // Loading
  const loading = ref(null)

  // Sort
  const sortBy = ref(null) // createdAt || updatedAt
  const isSortDirDesc = ref(true) //  ASC || DESC
  const contactsSortBy = ref(null) // createdAt || updatedAt
  const contactsIsSortDirDesc = ref(false) //  ASC || DESC

  // Filter
  const searchTextFilter = ref(null)
  const contactsSearchTextFilter = ref(null)
  const createdByFilter = ref(null)

  // check empty filter
  const isEmptyFilter = computed(() => !!(createdByFilter.value === null))

  const dataMeta = computed(() => {
    const localItemsCount = refCustomerListTable.value
      ? refCustomerListTable.value.localItems.length
      : 0
    return {
      from:
        sizePerPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: sizePerPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCustomers.value,
    }
  })
  const dataMetaContacts = computed(() => {
    const localItemsCount = refContactListTable.value
      ? refContactListTable.value.localItems.length
      : 0
    return {
      from:
        sizePerPageContacts.value * (currentPageContacts.value - 1)
        + (localItemsCount ? 1 : 0),
      to:
        sizePerPageContacts.value * (currentPageContacts.value - 1)
        + localItemsCount,
      of: totalContacts.value,
    }
  })

  const refetchData = () => {
    refCustomerListTable.value.refresh()
  }
  const refetchDataContacts = () => {
    refContactListTable.value.refresh()
  }

  const clearFilter = () => {
    searchTextFilter.value = null
    createdByFilter.value = null
    refetchData()
  }

  const contactsClearFilter = () => {
    contactsSearchTextFilter.value = null
    refetchDataContacts()
  }

  watch(
    [currentPage, sizePerPage, sortBy, isSortDirDesc, searchTextFilter],
    () => {
      refetchData()
    },
  )

  watch(
    [
      currentPageContacts,
      sizePerPageContacts,
      contactsSortBy,
      contactsIsSortDirDesc,
      contactsSearchTextFilter,
    ],
    () => {
      refetchDataContacts()
    },
  )

  // CUSTOMER
  const fetchCustomerById = id => {
    store
      .dispatch('app-customer/fetchCustomerById', { id })
      .then({})
      .catch(() => {
        toastError({
          title: 'Error fetching users list',
        })
      })
  }

  const fetchCustomers = (ctx, callback) => {
    loading.value = true
    const payload = {
      size: sizePerPage.value,
      page: currentPage.value,
      searchText: searchTextFilter.value || '',
      createdById: createdByFilter.value?.id,
      sortBy: sortBy.value ? sortBy.value : 'createdAt',
      sortDirection: isSortDirDesc.value ? 'DESC' : 'ASC',
    }
    store
      .dispatch('app-customer/fetchCustomers', payload)
      .then(response => {
        totalCustomers.value = response.data.total
        const cus = response.data
        callback(cus.items)
        loading.value = false
      })
      .catch(error => {
        console.error({ error })

        toastError({
          title: 'Error fetching customer list',
        })
        loading.value = false
      })
  }

  const addCustomer = customer => new Promise((resolve, reject) => {
    store
      .dispatch('app-customer/addCustomer', customer)
      .then(response => {
        toastSuccess({
          title: 'Thêm khách hàng thành công',
        })
        resolve(response)
      })
      .catch(error => {
        console.error({ error })
        reject(error)

        toastError({
          title: 'Error add customer',
        })
      })
  })

  const updateCustomer = (customerData, dataDifferent, callback) => {
    const dataToUpdate = {
      ...dataDifferent,
      addressCountry: dataDifferent.addressCountry
        ? dataDifferent.addressCountry.id
        : customerData.addressCountry?.id,
      addressCity: dataDifferent.addressCity
        ? dataDifferent.addressCity.id
        : customerData.addressCity?.id,
      addressDistrict: dataDifferent.addressDistrict
        ? dataDifferent.addressDistrict.id
        : customerData.addressDistrict?.id,
      addressWard: dataDifferent.addressWard
        ? dataDifferent.addressWard.id
        : customerData.addressWard?.id,
    }
    store
      .dispatch('app-customer/updateCustomer', {
        id: customerData.id,
        dataToUpdate,
      })
      .then(() => {
        callback()
      })
      .then(() => {
        toastSuccess({
          title: 'Chỉnh sửa khách hàng thành công',
        })
      })
      .catch(e => {
        console.error({ e })
        toastError({
          title: 'Lỗi chỉnh sửa khách hàng',
        })
      })
  }

  const deleteCustomer = customer => {
    store
      .dispatch('app-customer/deleteCustomer', { id: customer.id })
      .then(() => {
        toastSuccess({
          title: 'Xoá khách hàng thành công',
        })
      })
      .catch(() => {
        toastError({
          title: 'Lỗi xoá khách hàng',
        })
      })
  }

  const bulkDeleteCustomers = ids => {
    store
      .dispatch('app-customer/bulkDeleteCustomers', { ids })
      .then(() => {
        refetchData()
        toastSuccess({
          title: 'Xoá khách hàng thành công',
        })
      })
      .catch(() => {
        toastError({
          title: 'Lỗi xoá khách hàng',
        })
      })
  }

  // CONTACTS
  const getContactById = (id, contactId) => new Promise((resolve, reject) => {
    store
      .dispatch('app-customer/getContactById', { id, contactId })
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
        toastError({
          title: 'Error fetching users list',
        })
      })
  })

  const fetchContacts = (ctx, callback) => {
    const customerId = route.value.params.id
    const payload = {
      size: sizePerPageContacts.value,
      page: currentPageContacts.value,
      searchText: contactsSearchTextFilter.value || null,
      // sortBy: contactsSortBy.value ? contactsSortBy.value : 'createdAt',
      // sortDirection: contactsIsSortDirDesc.value ? 'DESC' : 'ASC',
    }
    store
      .dispatch('app-customer/fetchContacts', { customerId, payload })
      .then(response => {
        totalContacts.value = response.data.total
        const contacts = response.data.items
        callback(contacts)
      })
      .catch(e => {
        console.error({ e })
        toastError({
          title: 'Error fetching contact list',
        })
      })
  }

  const addContact = (id, contact, callback) => {
    store
      .dispatch('app-customer/addContact', { id, contact })
      .then(() => {
        toastSuccess({
          title: 'Thêm hành khách thành công',
        })
        callback()
      })
      .catch(error => {
        console.error({ error })
        toastError({
          title: 'Lỗi thêm hành khách',
        })
      })
  }

  const updateContact = (customerId, contactData, dataToUpdate, callback) => {
    store
      .dispatch('app-customer/updateContact', {
        id: customerId,
        contactId: contactData.id,
        dataToUpdate,
      })
      .then(() => {
        callback()
      })
      .then(() => {
        toastSuccess({
          title: 'Sửa thông tin hành khách thành công',
        })
      })
      .catch(error => {
        console.error({ error })
        toastError({
          title: 'Lỗi chỉnh sửa thông tin hành khách',
        })
      })
  }

  const deleteContact = (customerId, contact) => {
    store
      .dispatch('app-customer/deleteContact', {
        customerId,
        contactId: contact.id,
      })
      .then(() => {
        toastSuccess({
          title: 'Xoá hành khách thành công',
        })
        refetchDataContacts()
      })
      .catch(() => {
        toastError({
          title: 'Lỗi xoá liên hệ',
        })
      })
  }

  const exportCustomers = locale => {
    const timeZoneOffset = new Date().getTimezoneOffset()
    store
      .dispatch('app-customer/exportCustomers', { language: locale, timeZoneOffset })
      .then(() => {
        toastSuccess({
          title: 'Xuất excel khách hàng thành công',
        })
      })
      .catch(() => {
        toastError({
          title: 'Lỗi xuất excel liên hệ',
        })
      })
  }

  // Sample file csv
  const exportCustomersTemplateCsv = () => {
    store
      .dispatch('app-customer/exportCustomersTemplate')
      .then(() => {
        toastSuccess({
          title: 'Thành công',
        })
      })
      .catch(error => {
        console.error({ error })
        toastError({
          title: 'Lỗi tải file mẫu',
        })
      })
  }

  const importCustomers = arrayList => new Promise((resolve, reject) => {
    store
      .dispatch('app-customer/importCustomers', { customers: arrayList })
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })

  const fetchCountries = searchText => new Promise((resolve, reject) => {
    store
      .dispatch('app-customer/fetchCountries', { searchText })
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })

  const fetchAdministrativeUnits = payload => new Promise((resolve, reject) => {
    store
      .dispatch('app-customer/fetchAdministrativeUnits', payload)
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })

  function changeSocialNetwork(id, payload) {
    return new Promise((resolve, reject) => {
      store
        .dispatch('app-customer/changeSocialNetwork', { id, payload })
        .then(res => {
          resolve(res)
          toastSuccess({
            title: 'messagesList.success',
            content: 'messagesList.agency.edited',
          })
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: `${error.message || error}`,
          })
        })
    })
  }

  // handle select type contact
  function calculateAge(birthDay) {
    const dobArray = birthDay.split('-')
    const dobDate = new Date(
      dobArray[0],
      dobArray[1] - 1,
      dobArray[2].substring(0, 2),
    )
    const today = new Date()
    const ageInYears = (today - dobDate) / (1000 * 60 * 60 * 24 * 365)

    let type

    switch (true) {
      case ageInYears < 2:
        type = 'INFANT'
        break
      case ageInYears >= 2 && ageInYears < 12:
        type = 'CHILD'
        break
      case ageInYears >= 12:
        type = 'ADULT'
        break
      default:
        break
    }

    return type
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveContactTypeVariant = type => {
    if (type === 'Adult') return 'primary'
    if (type === 'Child') return 'light-primary'
    if (type === 'Infant') return 'light-danger'
    return 'primary'
  }

  return {
    // Customer
    fetchCustomerById,
    fetchCustomers,
    addCustomer,
    updateCustomer,
    deleteCustomer,
    bulkDeleteCustomers,
    tableColumns,
    sizePerPage,
    currentPage,
    totalCustomers,
    dataMeta,

    sortBy,
    isSortDirDesc,

    refCustomerListTable,

    resolveContactTypeVariant,

    refetchData,
    clearFilter,

    searchTextFilter,
    createdByFilter,

    // Contacts
    refContactListTable,
    tableContacts,
    fetchContacts,
    getContactById,
    addContact,
    updateContact,
    deleteContact,
    sizePerPageContacts,
    currentPageContacts,
    totalContacts,
    dataMetaContacts,
    contactsSearchTextFilter,

    contactsSortBy,
    contactsIsSortDirDesc,
    refetchDataContacts,
    contactsClearFilter,

    exportCustomers,
    exportCustomersTemplateCsv,
    importCustomers,

    // Address
    fetchCountries,
    fetchAdministrativeUnits,

    // Loading
    loading,

    isEmptyFilter,

    calculateAge,
    changeSocialNetwork,
  }
}
