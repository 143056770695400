import { apiErrorNoti } from '@/api/'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchErrorNoti(ctx, params) {
      return apiErrorNoti.fetchErrorNoti(params)
    },

    getErrorNotiById(ctx, { id }) {
      return apiErrorNoti.getErrorNotiById(id)
    },

    deleteErrorNoti(ctx, { id }) {
      return apiErrorNoti.deleteErrorNoti(id)
    },

    refuseToSign(ctx, { param, id }) {
      return apiErrorNoti.refuseToSign(param, id)
    },

    createErrorNoti(ctx, params) {
      return apiErrorNoti.createErrorNoti(params)
    },

    sendFptTvan(ctx, { param, id }) {
      return apiErrorNoti.sendFptTvan(param, id)
    },

    exportErrorNoti(ctx, payload, fileName = 'Error-notification') {
      return apiErrorNoti.exportErrorNoti(payload, fileName)
    },
  },
}
